import {
  faBedPulse,
  faChartUser,
  faHospitals,
  faHospitalUser,
  faMessage,
} from '@fortawesome/pro-regular-svg-icons';
import { Image } from '@quality24/design-system';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { HeadFC } from 'gatsby';
import * as React from 'react';

import SEO from '../../components/containers/SEO';
import Video from '../../components/atoms/Video';
import SolutionsTemplate from '../../components/templates/SolutionsTemplate';

import frame from '../../assets/ipad-frame.webp';
import poster from '../../assets/solutions/inpatient-poster.webp';
import video from '../../assets/videos/inpatient.mp4';
import videoWebm from '../../assets/videos/inpatient.webm';

import heroImage from '../../assets/solutions/inpatient0.webp';
import image1 from '../../assets/solutions/inpatient1.webp';
import image2 from '../../assets/solutions/inpatient2.webp';
import image3 from '../../assets/solutions/inpatient3.webp';
import image4 from '../../assets/solutions/inpatient4.webp';
import * as styles from './internacao.module.scss';

const InpatientPage: React.FunctionComponent = () => {
  const breakpoints = useBreakpoint();
  const isMobile = React.useMemo(() => breakpoints.lg === false, [breakpoints]);

  const HeroVideo = React.useMemo(
    () => (
      <div className={styles.ipad}>
        <Image className={styles.frame} src={frame} alt="laptop-frame" />
        <div className={styles.screen}>
          <Video
            className={styles.video}
            width={678}
            autoPlay={!isMobile}
            poster={poster}
            controls
            muted
            loop
          >
            <source src={videoWebm} type="video/webm" />
            <source src={video} type="video/mp4" />
            Tablet de internação
          </Video>
        </div>
      </div>
    ),
    [isMobile],
  );

  return (
    <SolutionsTemplate
      hero={{
        title: 'Internação',
        subtitle: 'Aumente a produtividade das equipes de atendimento',
        icon: faBedPulse,
        image: HeroVideo,
      }}
      cards={[
        {
          icon: faMessage,
          title: 'Feedback do paciente',
          content:
            'Pesquisa de satisfação sem burocracia e com mais agilidade ao SAC e Ouvidoria',
        },
        {
          icon: faChartUser,
          title: 'Dashboard',
          content: 'Insights críticos da sua operação em tempo real',
        },
        {
          icon: faHospitalUser,
          title: 'Dados',
          content:
            'Disponibilizamos dados e orientações sobre a jornada do paciente, conforme o perfil',
        },
        {
          icon: faHospitals,
          title: 'Chamados',
          content: 'Comunicação direta entre todos os departamentos',
        },
      ]}
      steps={[
        {
          image: image1,
          content:
            'Conexão direta entre os pacientes e diversas áreas da instituição de forma simples e funcional',
          width: '100%',
        },
        {
          image: image2,
          content:
            'Libere a enfermagem de tarefas não assistenciais e traga mais eficiência e agilidade ao dia-a-dia dos profissionais',
          width: '100%',
        },
        {
          image: image3,
          content:
            'Gerencie a experiência do paciente, dê mais agilidade ao SAC e Ouvidoria e diminua o número de detratores',
          width: '100%',
        },
        {
          image: image4,
          content:
            'Melhore o fluxo e a gestão do seu hospital, com dados em tempo real sobre a produtividade das equipes e a satisfação dos pacientes',
          width: '100%',
        },
      ]}
    />
  );
};

export default InpatientPage;

export const Head: HeadFC = () => (
  <SEO
    title="Internação | Soluções para Hospitais"
    description="Aumente a produtividade das equipes de atendimento."
    image={heroImage}
  />
);
